<template>
  <!-- Bootstrap footer -->
  <footer class="page-footer font-small text-white pt-4" style="background-color: #3e4551">
    <div class="container-fluid text-md-left">
      <div class="row">
        <div class="col-md-6 mt-md-0 mt-3">
          <h5 class="text-uppercase">J. Rose Contact</h5>
          <p>Here you can find multiple ways to contact me.</p>
          <p class="sources">I'm not being paid for this site.</p>
          <p class="sources">
            <a target="_blank" href="https://icons8.com/icon/w0vclwHRccbk/chain">Chain</a> icon by
            <a target="_blank" href="https://icons8.com">Icons8</a>
          </p>
          <img class="footer-img" src="@/assets/ar-emoji-pin2.jpg" alt="J. Rose pin" />
        </div>
        <hr class="clearfix w-100 d-md-none pb-3">
        <div id="instagram" class="col-md-3 mb-md-0 mb-3">
          <h5 class="text-uppercase">Instagram</h5>
          <ul class="list-unstyled">
            <li>
              <a href="https://www.instagram.com/jrosecow/" target="_blank">@JroseCow</a>
            </li>
          </ul>
        </div>
        <div id="email" class="col-md-3 mb-md-0 mb-3">
          <h5 class="text-uppercase">Email</h5>
          <ul class="list-unstyled">
            <li>
              <a href="mailto:jrosecow@gmail.com">Jrosecow@gmail.com</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="icons">
      <div class="container">
        <a href="https://www.instagram.com/jrosecow/">
          <font-awesome-icon icon="fa-brands fa-instagram" />
        </a>
        <a href="https://www.youtube.com/channel/UCHXVjeHdWiH37Gd_taVKudg/featured">
          <font-awesome-icon icon="fa-brands fa-youtube" />
        </a>
        <a href="https://open.spotify.com/playlist/5TIT8wEOGDG8zRED4bTTyq?si=kIU27pjjRhGXdqiaNczofw">
          <font-awesome-icon icon="fa-brands fa-spotify" />
        </a>
        <a href="https://www.snapchat.com/add/jr_moo">
          <font-awesome-icon icon="fa-brands fa-snapchat" />
        </a>
      </div>
    </div>
    <div class="footer-copyright text-center py-3">© 2019 Copyright:
      <a href="#"> Jrosecow</a>
    </div>
  </footer>
</template>